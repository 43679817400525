<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="scripts"
      :loading="scriptStatus.loading"
      :items-per-page="50"
      class="elevation-1"
      @click:row="clickedRow"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-chip color="success" outlined>{{ getScriptType(item.type) }}</v-chip>
      </template>

      <template v-slot:[`item.interval`]="{ item }">
        {{ item.type == 0 ? item.interval : "N/A" }}
      </template>

      <template v-slot:[`item.lastRan`]="{ item }">{{
        humanDate(item.lastRan)
      }}</template>

      <template v-slot:[`item.enabled`]="{ item }">{{
        item.enabled ? $t("common.yes") : $t("common.no")
      }}</template>

      <template v-slot:[`item.tagDeveui`]="{ item }">
        {{ item.type == 2 ? item.tagDeveui : "N/A" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { Roles } from "@/_helpers/Role";

export default {
  name: "ScriptList",
  data() {
    return {
      headers: [
        { text: this.$t("script.fields.name"), value: "name" },
        { text: this.$t("script.fields.type"), value: "type" },
        { text: this.$t("script.fields.interval"), value: "interval" },
        { text: this.$t("script.fields.lastRun"), value: "lastRan" },
        { text: this.$t("script.fields.enabled"), value: "enabled" },
        { text: this.$t("script.fields.boundTag"), value: "tagDeveui" },
      ],
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),

    ...mapState("scripts", { scriptStatus: "status", scripts: "scripts" }),

    Roles() {
      return Roles;
    },
  },
  methods: {
    ...mapActions("scripts", ["getScripts", "setCurrentScript"]),

    getScriptType(type) {
      return this.$t(`script.type.${type}`);
    },

    async clickedRow(item) {
      await this.setCurrentScript(item);

      if (this.permitted("Script.Update"))
        this.$router.push(`/script/${item.scriptId}`);
    },
  },

  created() {
    this.getScripts();
  },
};
</script>
